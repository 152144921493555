import axios from 'axios' // 引入axios
import { emitter } from '@/utils/bus.js'

const service = axios.create({
  baseURL: '/pschip',
  timeout: 99999
})
let acitveAxios = 0
let timer
const showLoading = () => {
  acitveAxios++
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(() => {
    if (acitveAxios > 0) {
      emitter.emit('showLoading')
    }
  }, 400)
}

const closeLoading = () => {
  acitveAxios--
  if (acitveAxios <= 0) {
    clearTimeout(timer)
    emitter.emit('closeLoading')
  }
}
// http request 拦截器
service.interceptors.request.use(
  config => {
    if (!config.donNotShowLoading) {
      showLoading()
    }
    return config
  },
  error => {
    if (!error.config.donNotShowLoading) {
      closeLoading()
    }
    alert(error)
    return error
  }
)

// http response 拦截器
service.interceptors.response.use(
  response => {
    if (!response.config.donNotShowLoading) {
      closeLoading()
    }
    if (response.data.code === 0 || response.headers.success === 'true') {
      if (response.headers.msg) {
        response.data.msg = decodeURI(response.headers.msg)
      }
      return response.data
    } else {
      alert(response.data.msg || decodeURI(response.headers.msg))
      if (response.data.data && response.data.data.reload) {
        localStorage.clear()
      }
      return response.data.msg ? response.data : response
    }
  },
  error => {
    if (!error.config.donNotShowLoading) {
      closeLoading()
    }

    if (!error.response) {
      alert(error)
      return
    }

    switch (error.response.status) {
      case 500:
        alert(error)
        break
      case 404:
        alert(error)
        break
    }

    return error
  }
)
export default service
