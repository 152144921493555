import service from '@/utils/request'

// @Tags PsCompanyIntroduction
// @Summary 用id查询公司介绍
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query model.PsCompanyIntroduction true "用id查询公司介绍"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /psCompanyIntroduction/findPsCompanyIntroduction [get]
export const findPsCompanyIntroduction = (params) => {
    return service({
        url: '/psCompanyIntroduction/findPsCompanyIntroduction',
        method: 'get',
        params
    })
}

// @Summary 获取公司简介
// @Produce  application/json
// @Router /psCompanyIntroduction/getPsCompanyIntroductionList [get]
export const getCompanyIntroduction = (data) => {
    return service({
        url: '/psCompanyIntroduction/getPsCompanyIntroductionList',
        method: 'get',
        data: data
    })
}

// @Tags PsChipInventory
// @Summary 用id查询芯片信息
// @accept application/json
// @Produce application/json
// @Param data query model.PsChipInventory true "用id查询芯片信息"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /psChipInventory/findPsChipInventory [get]
export const findPsChipInventory = (params) => {
    return service({
        url: '/psChipInventory/findPsChipInventory',
        method: 'get',
        params
    })
}

// @Tags PsChipInventory
// @Summary 分页获取芯片信息列表
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取芯片信息列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /psChipInventory/getPsChipInventoryList [get]
export const getPsChipInventoryList = (params) => {
    return service({
        url: '/psChipInventory/getPsChipInventoryList',
        method: 'get',
        params
    })
}

// @Tags PsDateCodes
// @Summary 用id查询生产日期
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query model.PsDateCodes true "用id查询生产日期"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /psDateCodes/findPsDateCodes [get]
export const findPsDateCodes = (params) => {
    return service({
        url: '/psDateCodes/findPsDateCodes',
        method: 'get',
        params
    })
}

// @Tags PsDateCodes
// @Summary 分页获取生产日期列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取生产日期列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /psDateCodes/getPsDateCodesList [get]
export const getPsDateCodesListWithCount = (params) => {
    return service({
        url: '/psDateCodes/getPsDateCodesListWithCount',
        method: 'get',
        params
    })
}

// @Tags PsManufacturers
// @Summary 用id查询制造商
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query model.PsManufacturers true "用id查询制造商"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /psManufacturers/findPsManufacturers [get]
export const findPsManufacturers = (params) => {
    return service({
        url: '/psManufacturers/findPsManufacturers',
        method: 'get',
        params
    })
}

// @Tags PsManufacturers
// @Summary 分页获取制造商列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取制造商列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /psManufacturers/getPsManufacturersList [get]
export const getPsManufacturersList = (params) => {
    return service({
        url: '/psManufacturers/getPsManufacturersList',
        method: 'get',
        params
    })
}

// @Tags PsManufacturers
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取制造商列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /psManufacturers/getPsManufacturersList [get]
export const getPsManufacturersListWithCount = (params) => {
    return service({
        url: '/psManufacturers/getPsManufacturersListWithCount',
        method: 'get',
        params
    })
}

// @Tags PsPackingTypes
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query model.PsPackingTypes true "用id查询包装类型"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /psPackingTypes/findPsPackingTypes [get]
export const findPsPackingTypes = (params) => {
    return service({
        url: '/psPackingTypes/findPsPackingTypes',
        method: 'get',
        params
    })
}

// @Tags PsPackingTypes
// @Summary 分页获取包装类型列表
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取包装类型列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /psPackingTypes/getPsPackingTypesList [get]
export const getPsPackingTypesList = (params) => {
    return service({
        url: '/psPackingTypes/getPsPackingTypesList',
        method: 'get',
        params
    })
}

// @Tags PsContactInformation
// @Summary 用id查询联系方式
// @accept application/json
// @Produce application/json
// @Param data query model.PsContactInformation true "用id查询联系方式"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /psContactInformation/findPsContactInformation [get]
export const findPsContactInformation = (params) => {
    return service({
        url: '/psContactInformation/findPsContactInformation',
        method: 'get',
        params
    })
}

// @Tags PsContactInformation
// @Summary 分页获取联系方式列表
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取联系方式列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /psContactInformation/getPsContactInformationList [get]
export const getPsContactInformationList = (params) => {
    return service({
        url: '/psContactInformation/getPsContactInformationList',
        method: 'get',
        params
    })
}
