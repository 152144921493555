<template>
    <div style="height: 350px;overflow-y: scroll;overflow-x: hidden;">
        <ul class="category-list">
            <li v-for="(brand, index) in brands" :key="`${brand.id}-${index}`">
                <a href="javascript:;" @click="getProductWithParent(brand.id)">
                    <span class="left-content">{{ brand.manufacturer }}</span>
                    <span class="count-text" style="font-size: 14px;padding-right: 20px;">{{ brand.total }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import BlogPostMixin from '../../mixins/BlogPostMixin'
    import {getPsManufacturersListWithCount} from "@/api/official";

    export default {
        name: 'BlogCategories',
        data() {
            return {
                brands: []
            }
        },
        async mounted() {
            const ret = await getPsManufacturersListWithCount({})
            this.brands = ret.data
        },
        methods: {
            getProductWithParent: function (manufacturerId) {
                this.$emit('notifyParent', {manufacturerId});
            }
        }
    }
</script>
