<template>
    <div style="height: 700px;overflow-y: scroll;overflow-x: hidden;">
        <ul class="tagcloud">
            <a v-for="(tag, index) in dateCodes" href="javascript:;" @click="getProductWithDC(tag.id)">
                {{ tag.dateCode }}
                <span class="badge text-bg-secondary">{{ tag.total }}</span>
            </a>
        </ul>
    </div>
</template>

<script>
    import {getPsDateCodesListWithCount} from "@/api/official";

    export default {
        name: 'BlogTags',
        data() {
            return {
                dateCodes: []
            }
        },
        async mounted() {
            const ret = await getPsDateCodesListWithCount({})
            this.dateCodes = ret.data
        },
        methods: {
            getProductWithDC: function (dateCodeId) {
                this.$emit('notifyParent', {dateCodeId});
            }
        }
    }
</script>
