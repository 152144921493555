<template>
    <router-view/>
</template>

<script>
    import AppFunctions from "@/helpers/AppFunctions";

    export default {
        created() {
            AppFunctions.toggleClass('body', 'active-light-mode')
        }
    }
</script>
