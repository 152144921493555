<template>
    <ul class="mainmenu">
        <li class="has-droupdown has-menu-child-item">
            <router-link to="/">Home</router-link>
        </li>
        <li class="has-droupdown has-menu-child-item">
            <router-link to="/product-showcase">Product Showcase</router-link>
        </li>
        <li><router-link to="/contact">Contact</router-link></li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>
