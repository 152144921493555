import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../views/About'
import ServicePage from '../views/ServicePage'
import ServiceDetailsPage from '../views/ServiceDetailsPage'
import CounterUpPage from '../views/CounterUpPage'
import ProgressbarPage from '../views/ProgressbarPage'
import AccordionPage from '../views/AccordionPage'
import SocialSharePage from '../views/SocialSharePage'
import BlogGridPage from '../views/BlogGridPage'
import BlogGridSidebarPage from '../views/BlogGridSidebarPage'
import BlogListPage from '../views/BlogListPage'
import BlogListSidebarPage from '../views/BlogListSidebarPage'
import BlogDetailsPage from '../views/BlogDetailsPage'
import BlogCategoryPosts from '../components/blog/BlogCategoryPosts'
import TeamPage from '../views/TeamPage'
import PortfolioPage from '../views/PortfolioPage'
import PortfolioThreeColumnPage from '../views/PortfolioThreeColumnPage'
import PortfolioFullWidthPage from '../views/PortfolioFullWidthPage'
import PortfolioGridLayoutPage from '../views/PortfolioGridLayoutPage'
import PortfolioBoxLayoutPage from '../views/PortfolioBoxLayoutPage'
import PortfolioDetailsPage from '../views/PortfolioDetailsPage'
import TestimonialPage from '../views/TestimonialPage'
import TimelinePage from '../views/TimelinePage'
import TabPage from '../views/TabPage'
import PricingPage from '../views/PricingPage'
import SplitSectionPage from '../views/SplitSectionPage'
import CallToActionPage from '../views/CallToActionPage'
import VideoPage from '../views/VideoPage'
import GalleryPage from '../views/GalleryPage'
import ContactPage from '../views/ContactPage'
import BrandPage from '../views/BrandPage'
import ErrorPage from '../views/404'
import AdvanceTabPage from '../views/AdvanceTabPage'
import BrandCarouselPage from '../views/BrandCarouselPage'
import AdvancePricingPage from '../views/AdvancePricingPage'
import BusinessConsulting2 from '../views/home-pages/BusinessConsulting2'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: BusinessConsulting2,
        meta: {
            title: 'SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: 'About || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/service',
        name: 'Service',
        component: ServicePage,
        meta: {
            title: 'Service || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/service-details',
        name: 'Service Details',
        component: ServiceDetailsPage,
        meta: {
            title: 'Service Details || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/counter',
        name: 'Counter',
        component: CounterUpPage,
        meta: {
            title: 'Counter || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/progressbar',
        name: 'Progressbar',
        component: ProgressbarPage,
        meta: {
            title: 'Progressbar || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/accordion',
        name: 'Accordion',
        component: AccordionPage,
        meta: {
            title: 'Accordion || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/social-share',
        name: 'Social share',
        component: SocialSharePage,
        meta: {
            title: 'Social Share || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/blog-grid',
        name: 'Blog grid',
        component: BlogGridPage,
        meta: {
            title: 'Blog Grid || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/product-showcase',
        name: 'Product Showcase',
        component: BlogGridSidebarPage,
        meta: {
            title: 'Product Showcase || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/blog-list',
        name: 'Blog list',
        component: BlogListPage,
        meta: {
            title: 'Blog List || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/blog-list-sidebar',
        name: 'Blog list sidebar',
        component: BlogListSidebarPage,
        meta: {
            title: 'Blog List Sidebar || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/blog-details/:id',
        name: 'Blog details ID',
        component: BlogDetailsPage,
        meta: {
            title: 'Blog Details || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/category/:slug',
        name: 'Blog Category List',
        component: BlogCategoryPosts,
        meta: {
            title: 'Blog Category List || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/team',
        name: 'Team',
        component: TeamPage,
        meta: {
            title: 'Team || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/portfolio',
        name: 'Portfolio',
        component: PortfolioPage,
        meta: {
            title: 'Portfolio || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/industry-insights',
        name: 'Industry Insights',
        component: PortfolioThreeColumnPage,
        meta: {
            title: 'Industry Insights || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/portfolio-full-width',
        name: 'Portfolio full width',
        component: PortfolioFullWidthPage,
        meta: {
            title: 'Portfolio Full Width || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/portfolio-grid-layout',
        name: 'Portfolio grid layout',
        component: PortfolioGridLayoutPage,
        meta: {
            title: 'Portfolio Grid Layout || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/portfolio-box-layout',
        name: 'Portfolio box layout',
        component: PortfolioBoxLayoutPage,
        meta: {
            title: 'Portfolio Box Layout || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/product-details/:id',
        name: 'Product details ID',
        component: PortfolioDetailsPage,
        meta: {
            title: 'Product Details || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/testimonial',
        name: 'Testimonial',
        component: TestimonialPage,
        meta: {
            title: 'Testimonial || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/timeline',
        name: 'Timeline',
        component: TimelinePage,
        meta: {
            title: 'Timeline || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/tab',
        name: 'Tab',
        component: TabPage,
        meta: {
            title: 'Tab || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: PricingPage,
        meta: {
            title: 'Pricing || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/spit-section',
        name: 'Split section',
        component: SplitSectionPage,
        meta: {
            title: 'Split Section || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/call-to-action',
        name: 'Call to action',
        component: CallToActionPage,
        meta: {
            title: 'Call to Action || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/video',
        name: 'Video',
        component: VideoPage,
        meta: {
            title: 'Video || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: GalleryPage,
        meta: {
            title: 'Gallery || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
        meta: {
            title: 'Contact || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/brand',
        name: 'Brand',
        component: BrandPage,
        meta: {
            title: 'Brand || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/error',
        name: 'Error',
        component: ErrorPage,
        meta: {
            title: '404 || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/advance-tab',
        name: 'Advance tab',
        component: AdvanceTabPage,
        meta: {
            title: 'Advance Tab || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/brand-carousel',
        name: 'Brand carousel',
        component: BrandCarouselPage,
        meta: {
            title: 'Brand Carousel || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/advance-pricing',
        name: 'Advance pricing',
        component: AdvancePricingPage,
        meta: {
            title: 'Advance Pricing || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/Product-details',
        name: 'Product details',
        component: PortfolioDetailsPage,
        meta: {
            title: 'Product Details || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/blog-details',
        name: 'Blog details',
        component: BlogDetailsPage,
        meta: {
            title: 'Blog Details || SOGOOD INDUSTRY LIMITED',
        },
    },
    {
        path: '/business-consulting-2',
        name: 'Business Consulting 2',
        component: BusinessConsulting2,
        meta: {
            title: 'Business Consulting 2 || SOGOOD INDUSTRY LIMITED',
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
