<template>
    <Layout header-class="header-left-align" nav-alignment="left">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-850 bg_image"
             data-black-overlay="7"
             :style="{'background-image': `url(${require(`@/assets/images/bg/chip1095.jpg`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <span class="subtitle" data-aos="fade-up" data-aos-delay="100">{{companyIntroduction.title1}}</span>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">{{companyIntroduction.title2}}</h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="210">{{companyIntroduction.title3}}</p>
                            <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                                <a class="btn-default btn-medium round btn-icon" style="background: none;">
                                    <div class="input-group mb-3" style="height: 55px;">
                                        <input type="text" class="form-control" style="background-color: white;height: 55px;" v-model="keywords" placeholder="Enter product keywords" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                        <span class="input-group-text" id="basic-addon2" style="font-size: 16px; color: white;   background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95% / 200% 100%;">
                                            <router-link :to="{name: 'Product Showcase', params:{goodsSn: keywords}}">Search</router-link>
                                        </span>
                                    </div>
                                </a>
                                <router-link class="btn-default btn-medium btn-border round btn-icon" to="/contact">
                                    Contact Us
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <Separator/>

        <!-- Start About Area -->
        <div class="rwt-about-area rn-section-gap">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="col-lg-5">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="../../assets/images/about/about-us.png" alt="About Images">
                        </div>
                    </div>
                    <div class="col-lg-7 mt_md--40 mt_sm--40">
                        <div class="content">
                            <div class="section-title">
                                <h2 class="title theme-gradient" data-aos="fade-up" data-aos-delay="280">
                                    {{companyIntroduction.companyName}} <br>
                                    <vue-typed-js :strings="companyIntroduction.actionTitle.split(',')"
                                      :loop="true"
                                      :type-speed="80"
                                      :start-delay="1000"
                                      :back-speed="50">
                                        <b class="is-visible theme-gradient typing">Finance.</b>
                                    </vue-typed-js>
                                </h2>
                                <p data-aos="fade-up" data-aos-delay="310" v-html="companyIntroduction.introduction"></p>
                                <div class="read-more-btn mt--40" data-aos="fade-up" data-aos-delay="370">
                                    <router-link class="btn-default" to="/about">
                                        <span>More About Us</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End About Area -->

        <Separator/>

        <!-- Start Team Area -->
        <div class="rwt-team-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Major Brands."
                            description="Providing You with One-stop Chip Services."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12 mt--10">
                        <Brand :brand-list="brandList2" :brand-style="2"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Team Area -->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import Separator from '../../components/elements/separator/Separator'
    import Icon from '../../components/icon/Icon'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import ServiceFive from '../../components/elements/service/ServiceFive'
    import Team from '../../components/elements/team/Team'
    import BlogPost from '../../components/blog/BlogPost'
    import CallToAction from '../../components/elements/callToAction/CallToAction'
    import Testimonial from '../../components/elements/testimonial/Testimonial'
    import Accordion from '../../components/elements/accordion/Accordion'
    import Brand from "@/components/elements/brand/Brand.vue";
    import {findPsCompanyIntroduction, getCompanyIntroduction, getPsManufacturersList} from "@/api/official";

    export default {
        name: 'BusinessConsulting2',
        components: {
            Brand,
            Accordion,
            Testimonial,
            CallToAction,
            BlogPost,
            Team,
            ServiceFive,
            SectionTitle,
            Icon,
            Separator,
            Layout
        },
        data() {
            return {
                brandList2: [],
                companyIntroduction: {},
                keywords: ''
            }
        },
        async mounted() {
            const ret = await findPsCompanyIntroduction({ID: 1})
            this.companyIntroduction = ret.data.repsCompanyIntroduction

            const brandRet = await getPsManufacturersList({homeShow: 1})
            let brands = []
            for (const key in brandRet.data.list) {
                this.brandList2.push({
                    image: brandRet.data.list[key].photo
                })
            }
        }
    }
</script>
