<template>
    <div class="rn-card box-card-style-default"
         :class="{'card-list-view': blogPostStyle === 'list'}">
        <div class="inner">
            <div class="content" style="position: relative;">
                <div style="position: absolute;right: 40px;top:10px; width: 20px; height: 20px;">
                    <a href="mailto:JUN@SG-IC.COM">
                        <i class="bi-envelope-at" style="font-size: 2rem; color: cornflowerblue;"></i>
                    </a>
                </div>
                <div style="position: absolute;right: 10px;top:10px; width: 20px; height: 20px;">
                    <a href="skype:OKJUNLIN?chat">
                        <i class="bi-skype" style="font-size: 2rem; color: cornflowerblue;"></i>
                    </a>
                </div>
                <img style="position: absolute;right: 0;bottom:0; width: 100px; height: 50px;" :src="blog.photoUrl" :alt="blog.partNumber">
                <ul v-if="blogPostStyle === 'card'" class="rn-meta-list">
                    <li style="font-size: 16px;">
                        <span style="color: #059DFF;">{{ blog.partNumber }}</span>
                    </li>
                    <li class="separator">/</li>
                    <li>
                        {{ blog.manufacturer.manufacturer }}
                    </li>
                    <li class="separator">/</li>
                    <li>{{ blog.date_code.dateCode }}</li>
                </ul>
                <h4 class="title">
                    Inv: {{ blog.quantity }}
                </h4>
                <ul v-if="blogPostStyle === 'list'" class="rn-meta-list">
                    <li>
                        {{ blog.manufacturer.manufacturer }}
                    </li>
                    <li class="separator">/</li>
                    <li>{{ blog.date_code.dateCode }}</li>
                </ul>
                <p v-if="blogPostStyle === 'list'" class="descriptiion">
                    {{ blog.excerpt }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from "@/components/icon/Icon.vue";

    export default {
        name: 'BlogPost',
        components: {Icon},
        props: {
            blog: {},
            blogPostStyle: {
                type: String,
                default: 'card'
            }
        }
    }
</script>
